@import url("./styles/_custom-properties.css");
@import url("./styles/typography.css");

::selection {
    background: var(--bc-brand);
    color: #fff;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    background-color: var(--bc-background);
    scroll-behavior: smooth;
    font-size: 19px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    color: var(--bc-text-color);
    display: flex;
    flex-direction: column;
    font-family: var(--bc-font-baloo);
    line-height: 1.4;
    min-height: 100dvh;
    position: relative;
}

#root::before {
    background-color: rgba(var(--bc-background-rgb), 0.6);
    mask: linear-gradient(to top, transparent, black 1em);
    backdrop-filter: blur(0.25rem);
    content: "";
    display: block;
    height: calc(var(--bc-logo-spacing) + 3em);
    position: fixed;
    width: 100%;
    z-index: 2;
}

img,
svg {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

a {
    color: var(--bc-action);
    text-decoration-thickness: 1.5px;
    text-underline-offset: 0.25em;
    transition: text-underline-offset 0.15s;
}

a:hover {
    text-underline-offset: -100%;
}

p {
    margin-bottom: 1em;

    + h2 {
        padding-top: calc(var(--bc-logo-spacing) * 3);
    }
}

strong {
    font-weight: normal;
    text-shadow: 0.02em 0.02em 0 var(--bc-background), 0.05em 0.05em 0 var(--bc-brand);
}

ul:not([class]) {
    list-style: none;

    li {
        margin-left: 0.6em;

        &::before {
            background-color: var(--bc-brand);
            border-radius: 50%;
            content: "";
            display: inline-block;
            height: 0.3em;
            margin-top: -0.1em;
            margin-left: -0.6em;
            margin-right: 0.3em;
            vertical-align: middle;
            width: 0.3em;
        }
    }
}

.center-text {
    text-align: center;
}

footer {
    margin-top: auto;
    padding-bottom: var(--bc-logo-spacing);
}

.main-body {
    > * {
        max-width: 40rem;
    }
}

.notfound {
    text-align: center;

    h1 {
        text-align: center;
    }
}

.visually-hidden:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.wrapper {
    padding-inline: var(--bc-logo-spacing);
    position: relative;
    z-index: 1;
}

.branding {
    border-top: var(--bc-logo-spacing) solid transparent;
    display: block;
    margin-bottom: var(--bc-logo-spacing);
    position: relative;
    z-index: 2;
}

.branding::after {
    border-bottom: var(--bc-logo-letter-responsive-thickness) solid var(--bc-border-color);
    border-radius: 10rem;
    bottom: calc(var(--bc-logo-spacing) / 4 * 3 / 2 * -1);
    content: "";
    display: block;
    margin-top: var(--bc-logo-spacing);
    margin-left: var(--bc-logo-spacing);
    mix-blend-mode: luminosity;
    position: relative;
    transform: rotate(1deg);
    width: calc(100dvw - (var(--bc-logo-spacing) * 2));
}

.branding-logo {
    display: block;
    height: var(--bc-logo-initial-height);
    margin-left: var(--bc-logo-spacing);
    overflow: hidden;
    transition: height var(--bc-logo-animation-speed);
    width: calc(100dvw - (var(--bc-logo-spacing) * 2));
}

.branding-logo svg {
    position: relative;
    transition: margin-top var(--bc-logo-animation-speed);
    vertical-align: top;
    width: 100%;

    .logo-corp {
        fill: var(--bc-corp);
    }
}

@media (max-width: 40rem) {
    main.wrapper,
    footer .wrapper {
        padding-inline: calc(var(--bc-logo-spacing) * 2);
    }
}

@media (min-width: 320px) {
    html {
        font-size: calc(19px + 6 * ((100vw - 320px) / 680));
    }
}

@media (min-width: 1114px) {
    html {
        font-size: 26px;
    }
}

@media (min-width: 90rem) {
    p + h2 {
        padding-top: calc(var(--bc-logo-spacing) * 2);
    }
}
