@font-face {
    font-family: "beyond";
    src: url("../Beyond.woff2") format("woff2");
    font-display: fallback;
    font-style: normal;
    font-weight: normal;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
    font-family: var(--bc-font-beyond);
    font-weight: normal;
    line-height: 1;
    margin-bottom: 0.5em;
}

h1,
.h1 {
    font-size: 38px;
    margin-bottom: calc(1em + 2vw);
    text-align: right;
}

@media (min-width: 320px) {
    h1,
    .h1 {
        font-size: calc(38px + 15 * ((100vw - 320px) / 680));
    }
}

@media (min-width: 2224px) {
    h1,
    .h1 {
        font-size: 80px;
    }
}
