:root {
    --bc-corp: #444;
    --bc-brand: #459281;
    --bc-primary-light: #eef7f5;
    --bc-primary-light-rgb: 238, 247, 245;
    --bc-border-color: rgba(223, 236, 233, 0.72);
    --bc-text-color: var(--bc-corp);
    --bc-action: #c74200;
    --bc-background: var(--bc-primary-light);
    --bc-background-rgb: var(--bc-primary-light-rgb);

    --bc-logo-width: 2253;
    --bc-logo-height: 360;
    --bc-logo-ascender: 90;
    --bc-logo-descender: 90;
    --bc-logo-letter-space: 40;
    --bc-logo-letter-thickness: 30;
    --bc-logo-animation-speed: 0.5s;

    --bc-logo-ascender-collapse: calc(((var(--bc-logo-ascender) / (var(--bc-logo-letter-space) * 2 + var(--bc-logo-width))) * 100) * -1dvw);
    --bc-logo-initial-height: calc(((var(--bc-logo-height) / (var(--bc-logo-letter-space) * 2 + var(--bc-logo-width))) * 100) * 1dvw);
    --bc-logo-spacing: calc((((var(--bc-logo-letter-space) / (var(--bc-logo-letter-space) * 2 + var(--bc-logo-width))) * 100) * 1dvw) * 1.2);
    --bc-logo-x-height: calc(((calc(var(--bc-logo-height) - var(--bc-logo-ascender) - var(--bc-logo-descender)) / (var(--bc-logo-letter-space) * 2 + var(--bc-logo-width))) * 100) * 1dvw);
    --bc-logo-letter-responsive-thickness: calc(((var(--bc-logo-letter-thickness) / (var(--bc-logo-letter-space) * 2 + var(--bc-logo-width))) * 100) * 1dvw);

    --bc-font-beyond: "beyond", "Baloo 2", sans-serif;
    --bc-font-baloo: "Baloo 2", sans-serif;
}

@media (prefers-color-scheme: dark) {
    :root {
        --bc-corp: #888;
        --bc-brand: #459281;
        --bc-background: #111;
        --bc-background-rgb: 17, 17, 17;
        --bc-border-color: #222;
        --bc-action: #e04b00;
    }
}
