.logo-list {
    border-radius: 0.5em;
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    list-style: none;
    margin: 0 0 1em;

    img {
        height: calc(1em / 26 * 34);
        width: auto;
    }
}

@media (min-width: 40rem) {
    .logo-list {
        max-width: calc(100dvw - var(--bc-logo-spacing));
    }
}

@media (prefers-color-scheme: dark) {
    .logo-list {
        background-color: #eee;
        padding: var(--bc-logo-spacing);
    }
}
