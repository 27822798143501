.navbar-wrapper {
    display: block;
    margin-bottom: calc(2em + 4vw);
    position: sticky;
    top: 0;
    z-index: 2;
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    font-family: var(--bc-font-beyond);
    font-size: 1.125rem;
    list-style: none;
    margin-right: calc(var(--bc-logo-spacing) * -1);
    margin-left: calc(var(--bc-logo-spacing) * -1);
    text-transform: lowercase;
}

.navbar__item {
    padding-right: var(--bc-logo-spacing);
    padding-left: var(--bc-logo-spacing);
    position: relative;
    white-space: nowrap;
}

.navbar__item:hover {
    .navbar {
        display: flex;
    }
}

.navbar__link,
.navbar__link--active {
    padding-bottom: calc(var(--bc-logo-letter-responsive-thickness) + 0.125em);
    text-decoration: none;
}

.navbar__link::before,
.navbar__link--active::before {
    background-color: transparent;
    border-radius: 3em;
    bottom: calc(var(--bc-logo-letter-responsive-thickness) * -1);
    box-shadow: 0 0 0 calc(var(--bc-logo-letter-responsive-thickness) / 10) var(--bc-action) inset;
    content: "";
    display: block;
    height: calc(var(--bc-logo-letter-responsive-thickness) / 2);
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: height 0.2s ease-in-out, width 0.2s ease-in-out, background-color 0.4s ease-in-out;
    width: calc(100% - (var(--bc-logo-spacing) * 2));
    z-index: -1;
}

.navbar__link--active::before {
    background-color: var(--bc-action);
    height: var(--bc-logo-letter-responsive-thickness);
    width: var(--bc-logo-letter-responsive-thickness);
}

@media (min-width: 375px) {
    .navbar {
        font-size: calc(21.375px + 9 * ((100vw - 320px) / 680));
    }
}

@media (min-width: 1728px) {
    .navbar {
        font-size: 40px;
    }
}
