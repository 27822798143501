.article {
    margin-bottom: calc(var(--bc-logo-x-height) * 2);
    overflow: hidden;

    p {
        line-height: 1.067;
        margin-bottom: 0.5em;
        text-align: center;
    }

    .bio {
        font-size: calc(100% / 19 * 16);

        li {
            line-height: 1.067;
            list-style: none;
            margin-bottom: 0.25em;
        }
    }
}

.figure {
    border: 10vw solid var(--bc-border-color);
    border-radius: 50%;
    margin-inline: auto;
    margin-bottom: -1.6rem;
    max-width: 17.5rem;
    transition: border-width 0.2s;
    width: 100%;
}

.heading {
    margin-bottom: 0.25em;
    text-align: center;
}

.picture {
    border-radius: 50%;
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;

    img {
        width: 100%;
    }
}

p + .article-list {
    margin-top: calc(2em + 4vw);
}

@media (min-width: 30rem) {
    .heading,
    .article p {
        text-align: left;
    }

    .article .bio li {
        margin-left: 0;

        &::before {
            margin-left: 0;
        }
    }

    .figure {
        border-width: 4vw;
        flex-shrink: 0;
        float: left;
        margin: 0;
        margin-right: 4vw;
        shape-outside: circle(50%);
        width: 30%;
    }
}

@media (min-width: 60rem) {
    .article {
        margin-bottom: var(--bc-logo-x-height);
    }

    .figure {
        border-width: 1.6rem;
        margin-right: 1.6rem;
    }
}

@media (min-width: 90rem) {
    .article-list {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        width: 90vw;

        .article {
            padding-right: 1.6rem;
            width: 50%;
        }
    }

    .figure {
        border-width: 2vw;
        margin-right: 2vw;
        width: 40%;
    }
}

@media (min-width: 140rem) {
    .article-list {
        width: 75vw;
    }
}
