.highlight {
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    font-size: 1.25em;
    justify-content: center;
    margin-bottom: var(--bc-logo-x-height);
    margin-left: calc(4vw - var(--bc-logo-spacing));
    height: 92vw;
    width: 92vw;
    padding-inline: 10vw;
    position: relative;

    &::before {
        border: 15vw solid var(--bc-border-color);
        border-radius: 50%;
        content: "";
        flex: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
    }
}

@media (min-width: 44rem) {
    .highlight {
        margin-left: 10rem;
        height: 20rem;
        padding-inline: 0;
        width: 20rem;

        &::before {
            border-width: 3.5rem;
            left: -10rem;
        }
    }
}
