.footer {
    font-size: calc(100% / 19 * 15);
    z-index: 1;
}

.footer__line {
    border-top: var(--bc-logo-letter-responsive-thickness) solid var(--bc-border-color);
    border-radius: 10rem;
    display: block;
    margin-top: calc(var(--bc-logo-spacing) * 2);
    margin-bottom: calc(var(--bc-logo-spacing) / 3);
    margin-left: var(--bc-logo-spacing);
    mix-blend-mode: luminosity;
    position: relative;
    top: calc(var(--bc-logo-spacing) / 4 * 3 / 2 * -1);
    transform: rotate(1deg);
    width: calc(100dvw - (var(--bc-logo-spacing) * 2));
}

.footer__wrapper {
    display: flex;
    flex-wrap: wrap;

    > * {
        margin-right: calc(var(--bc-logo-spacing) * 2);
    }
}
